<script lang="ts">
    import { Column, Text, Title } from 'Components/UI';
    import 'Assets/global.css';
    import * as Style from 'Components/Style';

    import loadingIndicator from 'Assets/loadingIndicator.gif';
    import translations from 'Assets/i18n';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let text: Nullable<string> = undefined;
</script>

<Column
    horizontalAlign="center"
    verticalAlign="center">
    <img
        alt={text}
        height={100}
        src={loadingIndicator}
        width={100} />
    <Title padding="20px 0">{text}</Title>
    <Text
        color={Style.colors.secondary}
        padding="16px 0">
        {translations.global.messages.pleaseWait}
    </Text>
</Column>
