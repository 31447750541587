import type NotificationLevel from 'Lib/Models/NotificationLevel';

import { v4 as uuidv4 } from 'uuid';

export default class NotificationModel {
    public readonly id = uuidv4();
    public readonly message: string;
    public readonly level: NotificationLevel;

    public constructor(message: string, level: NotificationLevel) {
        this.message = message;
        this.level = level;
    }
}
