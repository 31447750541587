import type {Action} from 'svelte/types/runtime/action';
import {createTippy} from 'svelte-tippy';
import 'tippy.js/dist/tippy.css';
import EnvironmentUtils from 'Lib/Utils/EnvironmentUtils';

const ANIMATION_TIME_IN_MS = 500;
const TIMEOUT = 1500;

const tippyBase = createTippy({
    animation: 'perspective-subtle',
    delay: [ANIMATION_TIME_IN_MS, null],
    arrow: false,
});

const tooltip: Action<HTMLElement, string | undefined> = (node, content) => {
    if (!content) return;
    const props: any = {content};

    if (EnvironmentUtils.isTouchEnabled())
        props.onShow = (instance: any) => {
            setTimeout(() => {
                instance.hide();
            }, TIMEOUT);
        };

    const tippy = tippyBase(node, props);
    return {
        destroy(): void {
            tippy.destroy();
        },
    };
};

export default tooltip;
