﻿<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let backgroundColor: Nullable<string> = undefined;

    $: style = {
        backgroundColor,
    };
</script>

<div use:applyStyle={style}>
    <slot />
</div>

<style>
    div {
        display: flex;
        width: fit-content;

        max-width: 100%;

        align-items: center;
        padding: 8px;
        margin-bottom: 8px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);

        color: var(--primary-text-color);
    }
</style>
