﻿export default abstract class EnvironmentUtils {
    public static isInWebkit() {
        return navigator.userAgent.match(/AppleWebKit/i) && !navigator.userAgent.match(/Chrome/i);
    }

    public static isInIOSFirefox() {
        return this.isInWebkit() && navigator.userAgent.match(/FxiOS/i);
    }
    
    public static isTouchEnabled(){
        return 'ontouchstart' in window;
    }
}
