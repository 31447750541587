<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import * as Style from 'Components/Style';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let horizontalAlign: Nullable<string> = Style.row.horizontalAlign;
    export let verticalAlign: Nullable<string> = Style.row.verticalAlign;
    export let gap: Nullable<string> = Style.spacing.gap;
    export let padding: Nullable<string> = Style.spacing.padding;
    export let wrap: Nullable<string> = Style.row.wrap;
    export let backgroundColor: Nullable<string> = Style.row.backgroundColor;
    export let filter: Nullable<string> = undefined;
    export let shadow: Nullable<string> = undefined;
    export let height: Nullable<string> = Style.unset;
    export let zIndex: Nullable<string> = undefined;
    export let width: Nullable<string> = undefined;

    $: style = {
        height,
        width,
        gap,
        padding,
        backgroundColor,
        filter,
        zIndex,
        alignItems: verticalAlign,
        justifyContent: horizontalAlign,
        flexWrap: wrap,
        boxShadow: shadow,
    };
</script>

<div use:applyStyle={style}>
    <slot />
</div>

<style>
    div {
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
    }
</style>
