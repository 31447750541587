<script lang="ts">
    import {Row, Title, Spinner} from 'Components/UI';
    import type {Nullable} from 'Lib/Utils/Nullable';

    export let title: Nullable<string> = '';
    export let isPage: boolean = false;
    export let isShown: boolean = false;
    export let canBeClosed: boolean = false;
    export let closeCallback: Nullable<() => void> = undefined;
    export let showLoading = false;

    function requestClose() {
        if (!canBeClosed) return;
        if (closeCallback) closeCallback();
        isShown = false;
    }
</script>

{#if isShown}
    <div
            class="overlay"
            class:isPage
            on:click={requestClose}>
        <div
                on:click|stopPropagation
                class="modal"
                class:isPage>
            <Row horizontalAlign="space-between" verticalAlign="flex-start" width="100%">
                <Title>
                    {title}
                </Title>
                {#if canBeClosed}
                    <button on:click={requestClose} class="close-button mdi mdi-close"></button>
                {/if}
            </Row>
            <slot name="content"/>
            <slot name="buttons"/>
            {#if showLoading}
                <div class="loadingOverlay">
                    <Spinner/>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style>
    .overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        background-color: rgba(0, 0, 0, 0.5);
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .modal {
        background-color: white;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        position: absolute;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .modal:not(.isPage) {
        gap: 1.5rem;
    }

    .modal.isPage {
        box-shadow: none;
    }

    .overlay.isPage {
        align-items: start;
        z-index: 1;
        background-color: white;
    }

    .close-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
    }

    .loadingOverlay {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
