import { v4 as uuidv4 } from 'uuid';

export type UnsubscriberFunction = () => void;
export class EventDefinition<T> {
    private readonly handlers = new Map<string, (type: T) => void>();

    public invoke(parameter: T): void {
        for (const [, handler] of this.handlers) {
            handler(parameter);
        }
    }

    public do(subscriberFunction: (type: T) => void): UnsubscriberFunction {
        const key = uuidv4();
        this.handlers.set(key, subscriberFunction);
        return () => this.handlers.delete(key);
    }
}
