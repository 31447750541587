// eslint-disable-next-line no-unused-vars, @typescript-eslint/naming-convention
interface String {
    format: (...replacements: string[]) => string;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!String.prototype.format) {
    String.prototype.format = function (...args: string[]): string {
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    };
}
