<script lang="ts">
    import * as Style from 'Components/Style';
    import Text from 'Components/Text.svelte';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let weight: Nullable<string> = Style.title.weight;
    export let size: Nullable<string> = Style.title.size;
    export let color: Nullable<string> = Style.colors.secondary;
    export let textAlign: Nullable<string> = Style.unset;
    export let padding: Nullable<string> = Style.text.padding;
</script>

<Text
    {color}
    {padding}
    {size}
    {textAlign}
    {weight}>
    <slot />
</Text>
