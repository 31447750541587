import type SingletonDependency from 'Lib/SingletonDependency';
import type IDependency from 'Lib/IDependency';

export default class DependencyContainer {
    private static readonly singletons: Map<string, SingletonDependency> = new Map<string, SingletonDependency>();

    public registerSingleton<T extends SingletonDependency>(
        singletonType: new (dependencyContainer: DependencyContainer) => T,
    ): void {
        const key = singletonType.name;
        if (this.isDependencyRegisteredByKey(key)) {
            return;
        }
        this.addSingleton(key, new singletonType(this));
    }

    public addSingleton<T extends SingletonDependency>(singletonType: string, singletonInstance: T): void {
        if (this.isDependencyRegisteredByKey(singletonType)) {
            return;
        }

        DependencyContainer.singletons.set(singletonType, singletonInstance);
    }

    public registerSingletonInterface<I extends IDependency, T extends SingletonDependency & I>(
        interfaceName: string,
        singletonType: new (dependencyContainer: DependencyContainer) => T,
    ): void {
        const key = singletonType.name;
        if (this.isDependencyRegisteredByKey(key)) {
            return;
        }

        this.addSingleton(interfaceName, new singletonType(this));
    }

    public addSingletonInterface<I extends IDependency, T extends SingletonDependency & I>(
        interfaceName: string,
        singleton: T,
    ): void {
        this.addSingleton(interfaceName, singleton);
    }

    public get<T extends SingletonDependency>(singletonType: new (dependencyContainer: DependencyContainer) => T): T {
        const dependency = DependencyContainer.singletons.get(singletonType.name);
        if (dependency === undefined)
            throw new Error(`Requested dependency was not registered (type: ${singletonType.name})`);

        // @ts-expect-error T is abstraction of SingletonDependency
        return dependency;
    }

    public getInterface<I extends IDependency>(interfaceName: string): I {
        const dependency = DependencyContainer.singletons.get(interfaceName);
        if (dependency === undefined)
            throw new Error(`Requested dependency was not registered (type: ${interfaceName})`);

        // @ts-expect-error T is abstraction of SingletonDependency
        return dependency;
    }

    public isDependencyRegistered<T extends SingletonDependency>(
        singletonType: new (dependencyContainer: DependencyContainer) => T,
    ): boolean {
        return this.isInterfaceRegistered(singletonType.name);
    }

    public isInterfaceRegistered(interfaceName: string): boolean {
        return DependencyContainer.singletons.has(interfaceName);
    }

    public onDestroy(): void {
        for (const singleton of DependencyContainer.singletons.values()) {
            singleton.onDestroy();
        }
    }

    private isDependencyRegisteredByKey(key: string): boolean {
        if (DependencyContainer.singletons.has(key)) {
            console.error(`The following dependency is already registered: ${key}`);
            return true;
        }

        return false;
    }
}
